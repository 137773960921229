@import '../../../scss/theme-bootstrap';

.full-width-2-row-v1 {
  background-color: transparent;
  background-position: center bottom;
  background-repeat: repeat-x;
  @include swap_direction(padding, 0 0 40px 0);
  &.no-bottom-padding {
    padding-bottom: 0;
  }
}

.full-width-2-row-v1 .outer-wrap {
  background-color: transparent;
  background-position: center bottom;
  background-repeat: no-repeat;
  margin: 0 auto;
  max-width: 964px;
  min-height: 560px;
  position: relative;
}

.full-width-2-row-v1 .top {
  display: table-cell;
  height: 190px;
  #{$ldirection}: 0;
  position: absolute;
  top: 40px;
  width: 100%;
  vertical-align: middle;
}

.full-width-2-row-v1 h3 {
  color: #87c2ae;
  font-size: 36px;
  line-height: 1;
  @include swap_direction(margin, 0 0 5px 0);
}

.full-width-2-row-v1 .subheading,
.full-width-2-row-v1 .subheading p {
  color: #121212;
  font-size: 22px;
  font-family: $base-font-family;
  line-height: normal;
  @include swap_direction(margin, 0 0 15px 0);
}

.full-width-2-row-v1 {
  .video {
    #{$ldirection}: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  #atff_clean,
  #atff_spp_module {
    hr {
      display: inline-block;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 960px) {
  .full-width-2-row-v1 .top {
    padding: 0 20px;
  }
}
